export default [
  {
    title: 'Главная',
    route: 'posts-list',
    icon: 'HomeIcon',
    accountTypes: ['ADMIN', 'MANAGER', 'CLIENT'],
  },
  {
    title: 'Проекты',
    route: 'projects',
    icon: 'BriefcaseIcon',
    accountTypes: ['ADMIN', 'MANAGER', 'CLIENT'],
  },
  {
    title: 'Заказы',
    route: 'orders',
    icon: 'FileTextIcon',
    accountTypes: ['ADMIN', 'MANAGER', 'CLIENT'],
  },
  {
    title: 'Прайс-лист',
    route: 'price',
    icon: 'GridIcon',
    accountTypes: ['ADMIN', 'MANAGER', 'CLIENT'],
  },
  {
    title: 'Корзина',
    route: 'cart',
    icon: 'ShoppingCartIcon',
    accountTypes: ['ADMIN', 'MANAGER', 'CLIENT'],
  },
  {
    title: 'Клиенты',
    route: 'clients',
    icon: 'UserIcon',
    accountTypes: ['ADMIN', 'MANAGER'],

  },
]
