<template>
  <div class="navbar-container d-flex content align-items-center">
    <!--Кнопка сокрытия меню (мобилка)-->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21"/>
        </b-link>
      </li>
    </ul>


    <!--Блок справа-->
    <b-navbar-nav class="nav align-items-center justify-content-between w-100">
      <div class="d-flex align-items-center">
        <!--        {{ $store.state['productClipboard'].productIds}}-->
        <!--        {{ $store.state['app.products'].productPickedMode }}-->
        <!--    Режим добавления товаров в зону    -->
        <div v-if="$store.state['app.products'].productPickedMode">
          <b-button
              class="ml-1"
              variant="primary"
              @click="addAllProducts"
              :disabled='$store.getters["app.products/totalCount"] < 1'
          >
            Добавить ( выбрано: {{ $store.getters["app.products/totalCount"] }} )
          </b-button>
          <b-button
              class="ml-1"
              variant="warning"
              @click="backToProject"
          >
            Отменить
          </b-button>
        </div>


        <b-button v-if="$store.state.app.clipboard.roomId && !$store.state.app.clipboard.zoneId" class="ml-1"
                  variant="warning" @click="resetRoomCopy">
          Отменить копирование помещения
        </b-button>
        <b-button v-if="$store.state.app.clipboard.zoneId" class="ml-1" variant="warning" @click="resetZoneCopy">
          Отменить копирование зоны
        </b-button>
      </div>
      <div class="d-flex align-items-center justify-content-end">
        <b-dropdown size="lg" variant="link" toggle-class="text-decoration-none" no-caret>
          <template #button-content>
            <div class="d-flex " style="align-items: center">
              <img src="/icons/money.svg" width="16" height="16" style="margin-right: 4px;">
              <div>{{ currency }}</div>
              <feather-icon icon="ChevronDownIcon"/>
            </div>
          </template>
          <b-dropdown-item @click="setCurrency('BYN')">BYN</b-dropdown-item>
          <b-dropdown-item @click="setCurrency('RUB')">RUB</b-dropdown-item>
          <b-dropdown-item @click="setCurrency(null)">USD</b-dropdown-item>
        </b-dropdown>
        <BlCartDropdown></BlCartDropdown>

        <!--Выпадающее меню-->
        <b-nav-item-dropdown
            right
            toggle-class="d-flex align-items-center dropdown-user-link"
            class="dropdown-user"
        >
          <template #button-content>
            <div class="d-sm-flex d-none user-nav">
              <p v-if="$store.state.app.user" class="user-name font-weight-bolder mb-0">
                {{ $store.state.app.user.last_name }} {{ $store.state.app.user.first_name }}
              </p>

              <span class="user-status">
              {{ accountType }}
              </span>
            </div>
            <b-avatar
                size="40"
                variant="light-primary"
                badge
                :src="srcAvatar"
                class="badge-minimal"
                badge-variant="success"
            />
          </template>

          <!--Выпадающее меню-->
          <b-dropdown-item link-class="d-flex align-items-center">
            <feather-icon size="16" icon="UserIcon" class="mr-50"/>
            <b-link :to="{ name: 'account-settings' }">
              <span :to="{ name: 'account-settings' }">Профиль</span>
            </b-link>
          </b-dropdown-item>
          <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
            <feather-icon size="16" icon="LogOutIcon" class="mr-50"/>
            <b-link :to="{ name: 'posts-list' }">
              <span :to="{ name: 'posts-list' }">Выход</span>
            </b-link>
          </b-dropdown-item>
        </b-nav-item-dropdown>
      </div>

    </b-navbar-nav>
  </div>
</template>

<script>
import api from "../../api";
import BlCartDropdown from "@/components/views/cart/BlCartDropdown";
import utils from "@/utils";
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BAvatar,
  BButton,
  BDropdown,
  BButtonGroup,
} from "bootstrap-vue";

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BAvatar,
    BlCartDropdown,
    BButton,
    BDropdown,
    BButtonGroup,
  },
  mounted() {
    if (!localStorage.getItem('user.currency')) {
      localStorage.setItem('user.currency', 'BYN')
    }
    this.currency = localStorage.getItem('user.currency')
  },
  methods: {

    setCurrency(currency) {
      localStorage.setItem('user.currency', currency)
      this.currency = currency
      window.location.reload()
    },

    // Режим добавления товаров в зону

    addAllProducts() {
      const tasks = []
      for (const item of this.$store.state["app.products"].pickedProducts) {
        console.log('ADD: ', item)
        // const task = api.projects.addToZone(item.count, item.id, this.$router.currentRoute.query.zone, this.$router.currentRoute.query.project, this.$router.currentRoute.query.room)
        const task = api.projects.addItemToZone(this.$router.currentRoute.query.zone, item.id, item.count, 0)
        tasks.push(task)
      }
      Promise.all(tasks).then((response) => {
        utils.notificate(this, "Товары добавлены")
      })
          .catch((err) => {
            const data = err.response.data;
            if (data && data.error) {
              utils.notificateError(self, data.message, data.errorCode);
            }
          }).finally(() => {
        this.backToProject()
      });


    },

    backToProject() {
      this.$store.dispatch('app.products/reset')
      this.$store.dispatch('app.products/setProductPickMode', false)
      const projectId = this.$router.currentRoute.query.project
      const url = projectId ? `/project/${this.$router.currentRoute.query.project}` : '/projects'
      this.$router.push(url)
    },


    logout() {
      const self = this;
      api.auth.logout().then((data) => {
        self.$router.push("/login");
      });
    },
    resetRoomCopy() {
      this.$store.commit('app/SET_CLIPBOARD_ROOM_ID', {projectId: null, roomId: null})
    },
    resetZoneCopy() {
      this.$store.commit('app/SET_CLIPBOARD_ZONE_ID', {projectId: null, roomId: null, zoneId: null})
    },
  },

  data() {
    return {
      profile: null,
      currency: null,
    };
  },
  computed: {

    srcAvatar() {
      const user = this.$store.state.app.user;
      if (user && user.avatar) {
        return user.avatar;
      } else {
        return "/static/noavatar.svg";
      }
    },
    accountType() {
      const accountTypeNames = {
        'CLIENT': 'Клиент',
        'ADMIN': 'Администратор',
        'MANAGER': 'Менеджер',
      }
      if (this.$store.state.app.user) {
        return accountTypeNames[this.$store.state.app.user.account_type]

      }
      return null;

    }
  },

  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {
      },
    },
  },
};
</script>
