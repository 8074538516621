<template>
  <b-nav-item-dropdown
      class="dropdown-cart mr-25"
      menu-class="dropdown-menu-media"
      right
      @show="fetchItems"
  >
    <template #button-content>
      <feather-icon
          :badge="$store.state.app.cart.count"
          class="text-body"
          icon="ShoppingCartIcon"
          size="21"
      />
    </template>

    <!-- Шапка -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          Корзина
        </h4>
        <b-badge
            pill
            variant="light-primary"
        >
          {{ $store.state.app.cart.count }} Товаров
        </b-badge>
      </div>
    </li>

    <!-- Товары -->
    <vue-perfect-scrollbar
        v-if="$store.state.app.cart.count"
        :settings="perfectScrollbarSettings"
        class="scrollable-container media-list scroll-area"
        tagname="li"
    >
      <b-media
          v-for="item in $store.state.app.cart.items"
          :key="item.product.name"
      >
        <template #aside>
          <b-img
              :src="item.product.image ? item.product.image : '/static/images/no_photo.png'"
              :alt="item.product.name"
              rounded
              width="62px"
          />
        </template>
        <feather-icon
            icon="XIcon"
            class="cart-item-remove cursor-pointer"
            @click="deleteItem(item)"
        />
        <div class="media-heading">
          <h6 class="cart-item-title">
            <b-link class="text-body">
              {{ item.product.name }}
            </b-link>
          </h6>
        </div>

        <div class="cart-item-qty ml-1">
          <b-form-spinbutton
              :value="item.count"
              @input="changeValue(item, $event)"
              min="1"
              size="sm"
          />
        </div>

        <h5 class="cart-item-price">
          {{ item.price | formatNumber }}
        </h5>
      </b-media>
    </vue-perfect-scrollbar>

    <!-- Футер -->
    <li
        v-if="$store.state.app.cart.items.length"
        class="dropdown-menu-footer"
    >
      <div class="d-flex justify-content-between mb-1">
        <h6 class="font-weight-bolder mb-0">
          Сумма:
        </h6>
        <h6 class="text-primary font-weight-bolder mb-0">
          {{ $store.state.app.cart.amount | formatNumber }}
        </h6>
      </div>
      <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          block
          :to="{ name: 'cart' }"
      >
        Заказать
      </b-button>
    </li>

    <p
        v-if="$store.state.app.cart.count == 0"
        class="m-0 p-1 text-center"
    >
      Ваша корзина пуста
    </p>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BBadge, BMedia, BLink, BImg, BFormSpinbutton, BButton,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'

import api from '@/api'

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BImg,
    BFormSpinbutton,
    VuePerfectScrollbar,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      items: [],
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
    }
  },
  computed: {
    // totalAmount() {
    //   let total = 0
    //   this.items.forEach(i => {
    //     total += i.price
    //   })
    //   return total
    // },
  },
  mounted() {
    this.fetchItems()
  },
  watch: {
    '$store.state.app.cart': function (newValue, oldValue) {
    }
  },
  methods: {
    fetchItems() {
      const self = this;
      api.store.getBasket().then(response => {
        const data = response.data.result;
        self.$store.commit('app/SET_CART', data)
        self.$forceUpdate()
      })
    },
    changeValue(item, count) {
      this.addItem(item.product.id, count - item.count)
    },
    addItem(product_id, count) {
      const self = this;
      self.isLoading = true;
      api.store
          .basketAddItem(product_id, count)
          .then((response) => {
            self.isLoading = false;
            const data = response.data.result;
            self.$store.commit('app/SET_CART', data)
            self.$forceUpdate()
          })
          .catch((err) => {
            self.isLoading = false;
          });
    },
    deleteItem(item) {
      const self = this;
      self.isLoading = true;
      api.store
          .basketDeleteItem(item.id)
          .then((response) => {
            self.isLoading = false;
            const data = response.data.result;
            self.fetchItems()
            self.$forceUpdate()
          })
          .catch((err) => {
            self.isLoading = false;
          });
    }
  },
}
</script>

<style lang="scss" scoped>
.dropdown-cart {
  .media {
    .media-aside {
      align-items: center;
    }
  }
}
</style>
